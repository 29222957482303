<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <div class="confirma">
                <h2>Tem certeza que deseja excluir esse usuário?</h2>

                <div class="buttons">
                    <Button texto="Cancelar" @click="$emit('fechaOverlay')" />
                    <Button texto="Excluir" isRemove @click="apagaUsuario()" />
                </div>
            </div>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    props: {
        id: {
            required: true,
        },
    },

    methods: {
        apagaUsuario() {
            const user = { id: this.id };

            this.$emit('apagaUsuario', user);
        },
    },
};
</script>

<style lang="scss" scoped>
.confirma {
    display: grid;
    gap: 30px;

    h2 {
        font-size: 1.8rem;
        letter-spacing: 0.5px;
    }
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
</style>
