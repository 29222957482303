<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaEdicao()">
                <div :class="{ error: nomeInvalido }">
                    <label for="nome">Nome:</label>
                    <input type="text" id="nome" name="nome" v-model="editNome" />
                </div>

                <div :class="{ error: lojaInvalida }">
                    <label for="loja">Loja:</label>
                    <input type="number" id="loja" name="loja" step="1" v-model="editLoja" />
                </div>

                <small class="form_error-msg" v-if="lojaInvalida">Loja não pode estar vazio.</small>
                <small class="form_error-msg" v-if="nomeInvalido">Nome não pode ter menos de 4 caracteres</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Overlay from '../UI/Overlay.vue';
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Overlay,
        Card,
        Button,
    },

    props: {
        nome: {
            required: true,
        },
        loja: {
            required: true,
        },
        id: {
            required: true,
        },
    },

    data() {
        return {
            lojaInvalida: false,
            nomeInvalido: false,
            editNome: '',
            editLoja: '',
            editId: '',
        };
    },

    created() {
        this.editNome = this.nome;
        this.editLoja = this.loja;
    },

    watch: {
        editNome(newValue) {
            if (newValue.trim().length > 2) {
                this.nomeInvalido = false;
            }
        },
        editLoja(newValue) {
            if (newValue != '') {
                this.lojaInvalida = false;
            }
        },
    },

    methods: {
        salvaEdicao() {
            if (this.validaEdicao() == false) return;

            const user = {
                nome: this.editNome,
                loja: this.editLoja,
                id: this.id,
            };

            this.$emit('salvaEdicao', user);
        },

        validaEdicao() {
            if (this.editNome.trim().length <= 2) {
                this.nomeInvalido = true;
                return false;
            }

            if (this.editLoja == '') {
                this.lojaInvalida = true;
                return false;
            }

            return true;
        },
    },
};
</script>
