<template>
    <Card>
        <div class="info">
            <div class="info_text">
                <strong>Nome:</strong>
                <span>{{ nome }}</span>
            </div>

            <div class="info_text">
                <strong>Loja:</strong>
                <span>{{ loja }}</span>
            </div>

            <div class="info_buttons">
                <div class="info_buttons_basic">
                    <Button texto="Editar" @click="editarUsuario()" />
                    <Button texto="Alterar Senha" isRemove @click="alterarSenha()" />
                </div>

                <button class="info_buttons_delete" @click="excluirUsuario()">Excluir</button>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';

export default {
    components: {
        Card,
        Button,
    },

    props: {
        nome: {
            required: true,
        },
        loja: {
            required: true,
        },
        senha: {
            required: true,
        },
        id: {
            required: true,
        },
    },

    methods: {
        editarUsuario() {
            const usuario = {
                loja: this.loja,
                nome: this.nome,
                senha: this.senha,
                id: this.id,
            };

            this.$emit('editarUsuario', usuario);
        },

        alterarSenha() {
            const usuario = {
                loja: this.loja,
                nome: this.nome,
                senha: this.senha,
                id: this.id,
            };

            this.$emit('alterarSenha', usuario);
        },

        excluirUsuario() {
            const usuario = {
                loja: this.loja,
                nome: this.nome,
                id: this.id,
            };

            this.$emit('excluirUsuario', usuario);
        },
    },
};
</script>

<style lang="scss">
.info {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_text {
        display: flex;
        justify-content: space-between;
    }

    &_buttons {
        display: grid;
        gap: 10px;

        &_basic {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }

        &_delete {
            border: 1px solid #ff0000;
            letter-spacing: .5px;
            width: 100%;
            border-radius: 5px;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            color: #ff0000;
            transition: 0.2s ease-in-out;
            font-size: 1.1rem;
            background-color: transparent;

            &:hover {
                background-color: #ff0000;
                color: #fff;
            }
        }
    }
}
</style>
