<template>
    <div class="container">
        <Title titulo="Usuários" />

        <div class="navigate">
            <SearchInput texto="Pesquisar por loja" @changeInput="searchText" />

            <Button texto="Novo Usuário" @click="isCreating = true" />
        </div>

        <Spinner v-if="isLoading" />

        <div class="usuarios" v-else>
            <transition-group name="fade" mode="out-in">
                <template v-if="usuariosFiltrados.length > 0">
                    <UserItem
                        v-for="usuario in usuariosFiltrados"
                        :key="usuario.id"
                        :nome="usuario.nome"
                        :loja="usuario.loja"
                        :senha="usuario.senha"
                        :id="usuario.id"
                        @editarUsuario="editarUsuario"
                        @alterarSenha="alterarSenha"
                        @excluirUsuario="excluirUsuario"
                    />
                </template>

                <template v-else>
                    <UserItem
                        v-for="usuario in todosUsuarios"
                        :key="usuario.id"
                        :nome="usuario.nome"
                        :loja="usuario.loja"
                        :senha="usuario.senha"
                        :id="usuario.id"
                        @editarUsuario="editarUsuario"
                        @alterarSenha="alterarSenha"
                        @excluirUsuario="excluirUsuario"
                    />
                </template>
            </transition-group>
        </div>

        <transition name="fade" mode="out-in">
            <EditaUser
                v-if="isEditing"
                @fechaOverlay="fechaOverlay"
                @salvaEdicao="salvaEdicao"
                :nome="nome"
                :loja="loja"
                :id="id"
            />
        </transition>

        <transition name="fade" mode="out-in">
            <EditaPasswordUser
                v-if="isEditingPassword"
                @fechaOverlay="fechaOverlay"
                @salvaNovaSenha="salvaNovaSenha"
                :id="id"
            />
        </transition>

        <transition name="fade" mode="out-in">
            <DeleteUser v-if="confirmDelete" @fechaOverlay="fechaOverlay" @apagaUsuario="apagaUsuario" :id="id" />
        </transition>

        <transition name="fade" mode="out-in">
            <NovoUser
                v-if="isCreating"
                :lojas="lojas"
                @fechaOverlay="fechaOverlay()"
                @salvaNovoUsuario="salvaNovoUsuario"
            />
        </transition>

        <Modal v-if="showModal" :isError="isError" :mensagem="modalMessage" @closeModal="showModal = false" />
    </div>
</template>

<script>
import Title from '../components/UI/Title.vue';
import SearchInput from '../components/SearchInput.vue';
import Button from '../components/UI/Button.vue';
import Modal from '../components/Modal.vue';
import UserItem from '../components/Users/UserItem.vue';
import NovoUser from '../components/Users/NovoUser.vue';
import EditaUser from '../components/Users/EditaUser.vue';
import EditaPasswordUser from '../components/Users/EditaPasswordUser.vue';
import DeleteUser from '../components/Users/DeleteUser.vue';
import Spinner from '../components/UI/Spinner.vue';
import axios from 'axios';

export default {
    components: {
        Title,
        SearchInput,
        Button,
        Modal,
        Spinner,
        UserItem,
        NovoUser,
        EditaUser,
        EditaPasswordUser,
        DeleteUser,
    },

    data() {
        return {
            nome: '',
            loja: '',
            id: '',
            isEditing: false,
            isEditingPassword: false,
            confirmDelete: false,
            isCreating: false,
            todosUsuarios: [],
            usuariosFiltrados: [],
            showModal: false,
            isError: false,
            modalMessage: '',
            user: null,
            isLoading: true,
            lojas: [],
            headers: null,
        };
    },

    created() {
        this.user = JSON.parse(sessionStorage.getItem('user'));
        this.headers = { Accept: 'application/json', authorization: 'Bearer ' + this.user.token };

        const requests = [
            this.axios.get(`${this.user.cliente}/usuarios`, { headers: this.headers }),
            this.axios.get(`${this.user.cliente}/lojas`, { headers: this.headers }),
        ];

        axios
            .all(requests)
            .then(
                axios.spread((usuarios, lojas) => {
                    this.todosUsuarios = usuarios.data;
                    this.lojas = lojas.data;
                })
            )
            .catch(() => {
                this.isError = true;
                this.showModal = true;
                this.modalMessage =
                    'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    beforeRouteEnter(to, from, next) {
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (permissoes.permissoes.tela_usuarios == 0) {
            next({ path: '/bloqueados' });
        } else {
            next();
        }
    },

    methods: {
        editarUsuario(usuario) {
            this.nome = usuario.nome;
            this.loja = usuario.loja;
            this.id = usuario.id;

            this.isEditing = true;
        },

        alterarSenha(usuario) {
            this.nome = usuario.nome;
            this.loja = usuario.loja;
            this.id = usuario.id;

            this.isEditingPassword = true;
        },

        excluirUsuario(usuario) {
            this.id = usuario.id;

            this.confirmDelete = true;
        },

        salvaEdicao(user) {
            this.axios
                .patch(`${this.user.cliente}/usuarios`, user, { headers: this.headers })
                .then(() => {
                    const existingUserIndex = this.todosUsuarios.findIndex((item) => item.id == user.id);

                    let updatedUsers = [...this.todosUsuarios];
                    updatedUsers[existingUserIndex] = user;

                    this.todosUsuarios = updatedUsers;

                    this.isError = false;
                    this.modalMessage = 'Usuário editado com sucesso.';
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.isEditing = false;
                    this.showModal = true;
                });
        },

        salvaNovaSenha(userPassword) {
            this.axios
                .patch(`${this.user.cliente}/usuarios/senha`, userPassword, { headers: this.headers })
                .then(() => {
                    this.isError = false;
                    this.modalMessage = 'Nova senha salva com sucesso.';
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.isEditingPassword = false;
                    this.showModal = true;
                });
        },

        apagaUsuario(user) {
            this.axios
                .delete(`${this.user.cliente}/usuarios`, {
                    headers: this.headers,
                    data: user,
                })
                .then(() => {
                    this.todosUsuarios = this.todosUsuarios.filter((item) => {
                        return item.id !== user.id;
                    });
                    this.usuariosFiltrados = this.usuariosFiltrados.filter((item) => {
                        return item.id !== user.id;
                    });

                    this.isError = false;
                    this.modalMessage = 'Usuário Excluido com sucesso!';
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.confirmDelete = false;
                    this.showModal = true;
                });
        },

        salvaNovoUsuario(user) {
            this.axios
                .post(`${this.user.cliente}/usuarios`, user, { headers: this.headers })
                .then(() => {
                    this.todosUsuarios.unshift(user);
                    this.isError = false;
                    this.modalMessage = 'Usuário cadastrado com sucesso.';
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.isCreating = false;
                    this.showModal = true;
                });
        },

        fechaOverlay() {
            this.isEditing = false;
            this.isEditingPassword = false;
            this.isCreating = false;
            this.confirmDelete = false;
            this.showModal = false;
        },

        searchText(text) {
            this.usuariosFiltrados = [];

            if (text.trim().length > 0) {
                this.usuariosFiltrados = this.todosUsuarios.filter((usuario) => {
                    return usuario.loja.toString().includes(text);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.navigate {
    display: grid;
    gap: 30px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
        gap: 50px;
        grid-template-columns: 350px 150px 150px;
    }
}

.usuarios {
    display: grid;
    gap: 30px;

    @media (min-width: 768px) {
        gap: 30px;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
        gap: 50px;
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
